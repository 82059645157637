	function SetCookie(key,val) {
        if (key == "mindata" || key == "alertDefinition" || key == "groupe_management" || key == "groupe_driver") {
            sessionStorage.setItem(key, JSON.stringify(val));
        }else if(key=="selectedLang" || key=="notification" || key=="autoupdate" || key=="autoupdateoutside" || key=="showpois" || key=="displayOverview"){
            localStorage.setItem(key, val);
        }else{
			sessionStorage.setItem(key,val);
        }
		return true;
	}
	function GetCookie(key) {
		if (typeof key !== 'undefined') {
			var val= "";
			if(key=="alertDefinition" || key=="mindata" || key=="currentuserdetails" || key=="groupe_management"  || key=="groupe_driver" || key=="groupe_management" ){
				if(sessionStorage.getItem(key) != null && sessionStorage.getItem(key) != "null" && sessionStorage.getItem(key) != 'undefined'){
					val=JSON.parse(sessionStorage.getItem(key));
				}else{
					return false;
				}
				if(key=="mindata")
					val=Object.values(val);
			}else if(key=="selectedLang" || key=="notification" || key=="autoupdate" || key=="autoupdateoutside" || key=="showpois"  || key=="displayOverview" ){
				if(key=="selectedLang" && localStorage.getItem(key)=="undefined" && localStorage.getItem(key)==null){
					val="fr";
				}else{
					val=localStorage.getItem(key);
				}
				
			}else{
				val=sessionStorage.getItem(key);
				}
			return val;
		}
		return '';
	}
	function DeleteCookie() {
		sessionStorage.clear();
		localStorage.clear();
	}
export default {
	SetCookie,GetCookie,DeleteCookie
};

